export const prerender = true;
export const ssr = true;

import type { PageLoad } from './$types';
export const load: PageLoad = async ({ setHeaders }) => {
	// 1h cache
	setHeaders({
		'Cache-Control': `max-age=${60 * 60}, s-maxage=${60 * 60}`
	});
};
