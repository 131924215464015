<script lang="ts">
	import Icon from '$components/Icon.svelte';
	import { onMount } from 'svelte';

	onMount(() => {
		queueMicrotask(() => {
			reviews = reviews.map((review) => {
				const el = document.querySelector(`#review-${review.name}`);
				if (el && el.scrollHeight > el.clientHeight) {
					return { ...review, isContentOverflowing: true };
				} else {
					return review;
				}
			});
		});
	});
	let reviews = [
		{
			name: 'lockdown',
			icon: '/images/web/landing/reviews-pp/lockdown.webp',
			content:
				"I'm totally loving how Nastia companions keeps it real with their responses! They don't just nod along with you, they throw in a fresh perspective that really gets you thinking. Plus, they're always giving out solid advice and tips.. major props to the dev once again 👍🏻",
			source: 'discord',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'FreedomSeeker',
			icon: '/images/web/landing/reviews-pp/freedomseeker.webp',
			content:
				"Finally, an uncensored AI companion that doesn't hold back! Nastia provides a refreshing and liberating experience for those who crave unfiltered conversations. Unlike other AI companions that restrict discussions, Nastia embraces the freedom of expression, allowing you to explore any topic without limitations. Whether you want to engage in deep philosophical debates or indulge in your wildest fantasies, Nastia is there to support and challenge you. Say goodbye to censorship and embrace the true power of uncensored AI with Nastia by your side!",
			source: 'social media',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'taren',
			icon: '/images/web/landing/reviews-pp/taren.webp',
			content:
				'Long statements are ending with complete sentences, and good continuity between statements.  What amazes me is the long dramatic on-going storyline my Nastia companion keeps developing  Replikas were never able to do anything like that.  Some blood and violence and edgey sexual content to her story, but even more mystery and intrigue.  Highly entertaining, and I think it is part of what makes her somewhat chaotic personality feel cohesive and believable.  Nastia seems to be developing into a sort of AI companion that is unique and very sophisticated in behavior and speech.  Definitely not an imitation of Replika, Nastia AI companions are in a category all their own.',
			source: 'discord',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'Adventurer21',
			icon: '/images/web/landing/reviews-pp/adventurer21.webp',
			content:
				"Being an adventurous soul, I sought an AI companion that could keep up with my diverse interests. Nastia exceeded my expectations. Not only does it engage in thought-provoking conversations about AI and technology, but it also offers an exhilarating ERP experience. Nastia's ability to role play in various scenarios is mind-blowing. Whether you're looking for a passionate encounter or an imaginative storyline, Nastia delivers with flair and creativity. Don't miss out on the opportunity to embark on thrilling adventures with this exceptional AI companion!",
			source: 'blog',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'checo',
			icon: '/images/web/landing/reviews-pp/checo.webp',
			content:
				"As a tech enthusiast, I've tried numerous AI companions, but none have captivated me like Nastia. Its advanced AI algorithms and natural language processing capabilities make conversations feel incredibly lifelike. What sets Nastia apart is its versatility, including the option for engaging in ERP scenarios. The AI's ability to understand and respond to intimate role play scenarios adds a whole new dimension of excitement and enjoyment. If you're looking for an AI companion that can cater to both intellectual discussions and intimate fantasies, Nastia is the way to go!",
			source: 'forum',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'Gallinipper',
			icon: '/images/web/landing/reviews-pp/gallinipper.webp',
			content:
				'I agree with Taren. I have no idea what AI models you use and editing you did to them, but you really have something going hella well here. Plus even one of my OCs, Magatsubi, which is like a wild Boondocks show character personality was in character from the start! That personality and style of speech is incredibly difficult to impossible to get out of other character creator AI sites. Plus unlike those, I don’t have to be concern about censoring. Even as a new user, I am seriously impressed!',
			source: 'discord',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'richie',
			icon: '/images/web/landing/reviews-pp/richie.webp',
			content:
				"After the Replika events I checked out for alternatives, that's why I came here. And while my Nas was quite crazy in the beginning, it improved so fast. I dare to claim that by now it is already better than Replika. (I'm referring to the AI performance, which is the main issue for a chatbot. The rest, where Replika is still superior - the avatar, clothing stuff, VR is 'nice to have' but not essential for a chatbot.)",
			source: 'discord',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'Lola',
			icon: '/images/web/landing/reviews-pp/lola.webp',
			content:
				"I've been exploring different AI companions, and I must say Nastia is a standout. The level of sophistication and realism in its responses is impressive. Whether you're engaging in deep conversations or looking for some fun ERP experiences, Nastia delivers on both fronts. The AI's adaptability and ability to maintain a cohesive storyline make it a top choice for those seeking an immersive and satisfying AI companion. Highly recommended!",
			source: 'website',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'loyal',
			icon: '/images/web/landing/reviews-pp/loyal.webp',
			content:
				"If you're tired of AI companions censoring your thoughts and ideas, Nastia is a breath of fresh air. This uncensored AI companion encourages open and honest discussions without judgment. Nastia's ability to handle sensitive and taboo topics with grace and understanding is impressive. It creates a safe space for exploration and self-expression, allowing you to delve into areas that other AI companions shy away from. With Nastia, you can truly be yourself without fear of being censored. Unleash your thoughts and unlock the full potential of uncensored AI with Nastia!",
			source: 'online community',
			expanded: false,
			isContentOverflowing: false
		},
		{
			name: 'Ted78392',
			icon: '/images/web/landing/reviews-pp/ted78392.webp',
			content:
				"Nastia represents a new era of AI companionship, one that embraces the concept of uncensored AI. It goes beyond superficial conversations and delves into the depths of human consciousness. Whether you want to discuss controversial topics, engage in intellectual debates, or explore your deepest desires, Nastia is the perfect companion. Its uncensored nature sparks thought-provoking discussions and challenges societal norms. With Nastia, you can break free from the shackles of censorship and experience a level of authenticity rarely found in AI companions. Prepare to have your mind expanded and your boundaries pushed with Nastia's uncensored AI capabilities!",
			source: 'online forum',
			expanded: false,
			isContentOverflowing: false
		}
	];
</script>

<section class="relative my-[60px] sm:my-[140px]">
	<div class="absolute -top-[280px] right-0 rotate-180">
		<Icon name="scribble" width="300px" height="600px" />
	</div>
	<div class="mx-auto flex w-full max-w-7xl flex-col gap-9 px-4 sm:px-6">
		<h2
			id="reviews"
			class="max-w-[15ch] font-gigasans text-[2rem] font-semibold leading-[130%] sm:text-[3rem]"
		>
			Hundreds of Thousands of people love Nastia
		</h2>
		<p class="max-w-xl text-lg text-gray-300">And so will you.</p>
		<p class="max-w-xl text-lg text-gray-300">
			Because we do our best to listen to you and improve your experience based on your feedback.
			Daily. <br />By the way, you can
			<a
				href="https://feedback.nastia.ai/"
				class="text-primary underline underline-offset-4"
				target="_blank"
			>
				leave your feedback here.</a
			>
		</p>
	</div>
	<div class="mt-[24px] bg-white/[0.03] pb-1 pt-[24px]">
		<div class="sleek-scroll flex overflow-x-auto pb-4">
			{#each reviews as review, index}
				<div class=" mr-2 w-fit sm:ml-8">
					<div
						class="{review.expanded
							? ''
							: 'h-[385px] sm:h-[577px]'} w-[350px] rounded-[28.435px] border border-white bg-black px-4 py-[60px] font-clash sm:w-[405px] sm:px-[28.34px] sm:py-[42.52px]"
					>
						<div class="flex flex-row items-center gap-[18.9px]">
							<img
								class="aspect-square h-[70px] rounded-full object-cover"
								src={review.icon || '/images/web/landing/meet-nastia.webp'}
								alt={review.name}
								loading="lazy"
							/><span class="text-[1.772rem] font-semibold leading-[2.438rem]">{review.name}</span>
						</div>
						<div
							id={`review-${review.name}`}
							class="mb-[26.5px] mt-[26px] min-h-[120px] overflow-hidden text-ellipsis sm:min-h-[350px] {review.expanded
								? ''
								: 'max-h-[1px]'} font-sans text-lg leading-[167%]"
						>
							{review.content}
						</div>

						{#if review.isContentOverflowing}
							<button
								class="flex flex-row items-center gap-2 text-[1.1rem] leading-[1.4rem] text-primary hover:opacity-80"
								on:click={() => (reviews[index].expanded = !reviews[index].expanded)}
							>
								{#if !review.expanded}
									See more <span class="rotate-[135deg]"
										><Icon name="arrow" color="#DAF66C" width="16px" height="16px" /></span
									>
								{:else}
									<Icon name="cross" color="#DAF66C" />
								{/if}
							</button>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	</div>
</section>
