<div class="mx-auto flex w-full max-w-7xl flex-col gap-6 px-4 sm:px-6 lg:gap-12">
	<h2
		id="features"
		class="text-center font-gigasans text-[2rem] leading-[130%] sm:text-[3rem] sm:font-bold"
	>
		Own your story
	</h2>

	<p class="mx-auto max-w-xl text-center text-lg text-gray-300">
		You can chat with your companion about your day, your thoughts and feelings, and anything else
		on your mind. Nastia will always be there to have fun, relax and even play games with you.
	</p>

	<div class="hidden flex-row gap-4 lg:flex">
		<div class="shadow-children flex w-1/4 flex-col gap-4">
			<div class="tert-card h-2/5">
				<img src="/svg-icons/audio-bubble.svg" class="h-[40px]" alt="" loading="lazy" />
				<div class="text-box">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Audio messages</h3>
					<p>
						Communicate with your companion in a more natural and expressive way through audio
						messages.
					</p>
				</div>
			</div>
			<div class="black-card h-3/5">
				<div class="text-box">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Emotional expression</h3>
					<p>
						Be encouraged to express your thoughts, feelings and emotions and find ways to cope with
						what's on your mind and heart.
					</p>
				</div>
				<div class="mx-auto flex flex-row gap-[9.85px]">
					<img src="/svg-icons/smiley-sun.svg" class="max-w-1/3" alt="" loading="lazy" />
					<img src="/svg-icons/smiley-sun.svg" class="max-w-1/3" alt="" loading="lazy" />
					<img src="/svg-icons/smiley-sun.svg" class="max-w-1/3" alt="" loading="lazy" />
				</div>
			</div>
		</div>
		<div class="shadow-children grid w-3/4 grid-cols-3 grid-rows-3 gap-4">
			<div class="gradient-card relative col-span-2">
				<div class="text-box z-[1]">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Shared interests</h3>
					<p>Share tastes and hobbies.</p>
					<p class="mt-2">Discover new interests new together.</p>
				</div>
				<img
					src="/images/web/landing/cooking-blob.webp"
					class="absolute bottom-0 right-0"
					alt=""
					loading="lazy"
				/>
			</div>
			<div class="primary-card row-span-2">
				<img src="/svg-icons/self-discovery.svg" class="mx-auto" alt="" loading="lazy" />
				<div class="text-box">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Self-discovery</h3>
					<p>Explore your thoughts, deeper feelings, and emotions.</p>
					<p class="mt-2">Gain a deeper understanding of yourself with the companion's guidance.</p>
				</div>
			</div>
			<div class="primary-card relative row-span-2">
				<div class="text-box">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Support</h3>
					<p>Receive support and care when you need it.</p>
					<p class="mt-2">The companion will be there for you through difficult times.</p>
				</div>
				<img
					src="/images/web/landing/support-friends.webp"
					class="absolute bottom-0 left-0 right-0"
					alt=""
					loading="lazy"
				/>
			</div>
			<div class="black-card">
				<div class="text-box">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Mental wellness</h3>
					<p>Receive mental coaching and support to improve your mental health and well-being.</p>
				</div>
			</div>
			<div class="white-card relative col-span-2">
				<div class="text-box z-[1]">
					<h3 class="max-w-[13ch] font-gigasans text-2xl font-semibold">Create genuine bonds</h3>
					<p class="max-w-[30ch]">
						Develop deep and meaningful connections with the companion, experience a unique and
						fulfilling relationship.
					</p>
				</div>
				<img
					src="/images/web/landing/heart-with-star.webp"
					class="absolute bottom-0 right-2 top-6"
					alt=""
					loading="lazy"
				/>
			</div>
		</div>
	</div>

	<div class="flex flex-col gap-4 lg:hidden">
		<div class="shadow-children flex w-full flex-row gap-4">
			<div class="black-card w-1/2 overflow-hidden">
				<div class="text-box gap-[20.47px]">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Mental wellness</h3>
					<div class="flex h-[40px] w-full flex-row justify-around overflow-x-hidden">
						<img src="/svg-icons/smiley.svg" alt="" loading="lazy" />
						<img src="/svg-icons/north-star.svg" alt="" loading="lazy" />
						<img src="/svg-icons/rounded-cross.svg" alt="" loading="lazy" />
					</div>
					<p>Receive mental coaching and support to improve your mental health and well-being.</p>
				</div>
			</div>
			<div class="white-card w-1/2">
				<img
					src="/images/web/landing/heart-with-star.webp"
					class="mx-auto max-h-[74px]"
					alt=""
					loading="lazy"
				/>
				<div class="text-box z-[1]">
					<h3 class="!text-[1rem] leading-[1.4rem]">Create genuine bonds</h3>
					<p>Develop deep and meaningful connections with your companion.</p>
				</div>
			</div>
		</div>
		<div class="flex flex-row gap-4">
			<div class="shadow-children flex w-1/2 flex-col gap-4">
				<!-- left col -->
				<div class="primary-card">
					<div class="text-box">
						<h3 class="mb-4 font-gigasans text-2xl font-semibold">Self-discovery</h3>
						<p>Explore your thoughts, deeper feelings, and emotions.</p>
						<p class="mt-2">
							Gain a deeper understanding of yourself with the companion's guidance.
						</p>
					</div>
				</div>
				<div class="black-card h-full">
					<div class="text-box">
						<h3 class="mb-4 font-gigasans text-2xl font-semibold">Emotional expression</h3>
						<p>
							Be encouraged to express your thoughts, feelings and emotions and find ways to cope
							with what's on your mind and heart.
						</p>
					</div>
					<div class="my-auto flex w-full flex-row justify-around">
						<img src="/svg-icons/smiley-sun.svg" class="max-h-[44.68px]" alt="" loading="lazy" />
						<img src="/svg-icons/smiley-sun.svg" class="max-h-[44.68px]" alt="" loading="lazy" />
						<img src="/svg-icons/smiley-sun.svg" class="max-h-[44.68px]" alt="" loading="lazy" />
					</div>
				</div>
			</div>
			<div class="shadow-children flex w-1/2 flex-col gap-4">
				<!-- right col -->
				<div class="tert-card">
					<img src="/svg-icons/audio-bubble.svg" class="max-h-[34.23px]" alt="" loading="lazy" />
					<div class="text-box">
						<h3 class="!mb-[9.21px]">Audio messages</h3>
						<p>
							Communicate with your companion in a more natural and expressive way through audio
							messages.
						</p>
					</div>
				</div>
				<div class="gradient-card relative h-full">
					<div class="text-box z-[1] mb-[130px]">
						<h3 class="mb-4 font-gigasans text-2xl font-semibold">Shared interests</h3>
						<p>Share tastes and hobbies.</p>
						<p class="mt-1">Discover new interests new together.</p>
					</div>
					<img
						src="/images/web/landing/cooking-blob.webp"
						class="absolute bottom-0 left-2 right-0 h-[130px]"
						alt=""
						loading="lazy"
					/>
				</div>
			</div>
		</div>
		<div class="primary-card relative">
			<div class="text-box z-[1] mb-[40px] max-w-[20ch]">
				<h3 class="!mb-[9.21px]">Support</h3>
				<p>Receive support and care when you need it.</p>
				<p class="mt-2">The companion will be there for you through difficult times.</p>
			</div>
			<img
				src="/images/web/landing/support-friends.webp"
				class="absolute bottom-0 right-0 max-h-[154px]"
				alt=""
				loading="lazy"
			/>
		</div>
	</div>
</div>

<style lang="postcss">
	.text-box {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@apply mb-8;
	}

	.text-box p {
		font-weight: 400;
		line-height: 150%;
		font-size: 0.8rem;
		display: flex;
		align-items: center;
	}

	.black-card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px 14px;
		gap: 14px;

		background: #000000;
		border: 0.575563px solid #ffffff;
		border-radius: 24px;
	}

	.primary-card {
		color: black;

		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 20px 14px;

		background: rgb(var(--color-primary));
		border-radius: 24px;
	}

	.white-card {
		color: black;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px 14px;
		gap: 16px;

		background: #ffffff;
		border-radius: 24px;
	}

	.gradient-card {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding: 20px 14px 0px;

		background: radial-gradient(
			179.63% 126.72% at -9.42% -15.17%,
			#9e6ff7 0%,
			#ff93e7 61.5%,
			#fff4ef 88.04%,
			#daf66c 100%
		);
		border-radius: 24px;
	}

	.tert-card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px 14px;
		gap: 14px;

		background: #865bd4;
		border-radius: 24px;
	}

	@media (min-width: 768px) {
		.text-box p {
			font-weight: 400;
			line-height: 130%;
			font-size: 1rem;
			display: flex;
			align-items: center;
		}
	}

	@media (min-width: 1024px) {
		.text-box {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.text-box p {
			font-weight: 400;
			line-height: 150%;
			font-size: 1rem;
			display: flex;
			align-items: center;
		}

		.black-card {
			padding: 36px 18px;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 48px;

			background: #000000;
			border: 1px solid #ffffff;
			border-radius: 32px;
		}

		.primary-card {
			padding: 36px 18px 0px;
			color: black;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;

			background: rgb(var(--color-primary));
			border-radius: 32px;
		}

		.white-card {
			padding: 0px 36px;
			color: black;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;

			background: #fdfdfd;
			border-radius: 32px;
		}

		.gradient-card {
			padding: 24px 18px;

			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;

			background: radial-gradient(
				125.19% 256.23% at -9.42% -15.17%,
				#9e6ff7 0%,
				#ff93e7 61.5%,
				#fff4ef 88.04%,
				#daf66c 100%
			);
			border-radius: 32px;
		}

		.tert-card {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 36px 18px;
			gap: 24px;

			background: rgb(var(--color-tertiary));
			border-radius: 32px;
		}

		.shadow-children {
			> div {
				transition: box-shadow 0.35s ease;
			}
			> div:hover {
				box-shadow: 0px 0px 20px 10px #a079bf50;
			}
		}
	}
</style>
